import Router from 'next/router'

const IndexPage = (props) => {
  const org = props.selectedOrg?.name;

  if (org) {
    Router.replace(`/${org}`);
  }

  return null;
}

export default IndexPage;
